<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        
        <b-message
            v-for="(erro, index) in erros"
            v-bind:key="index"
            :title="$t('CHECKPOINT.ERRO')"
            type="is-danger"
            :aria-close-label="$t('CHECKPOINT.FECHARMESAGEM')">
            {{ erro }}
        </b-message>
        
        <div class='box'>
            <div class="columns">
                <div class="column">
                    <label><strong>{{$t('CHECKPOINT.SETOR')}}</strong></label>
                    <b-taginput :data="setores"
                                autocomplete
                                allow-new
                                v-model="setoresEscolhidos"
                                :placeholder="$t('CHECKPOINT.INCLUISETOR')"
                                @typing="getSetores">
                    </b-taginput>
                </div>
                <div class="column">
                    <label><strong>{{$t('SISTEMA.CONVENIO')}}</strong></label>
                    <b-taginput :data="convenios"
                                autocomplete
                                allow-new
                                v-model="conveniosEscolhidos"
                                :placeholder="$t('CHECKPOINT.INCLUICONVENIO')"
                                @typing="getConvenios">
                    </b-taginput>
                </div>
            </div>
        </div>


        <b-table v-if="amostras.length > 0" :data="amostras"
                    :loading="loading"
                    striped
                    hoverable
                    :checked-rows.sync="checkedRows"
                    checkable
                    checkbox-position="left"
                    sort-icon-size="is-small"
                    @sort="onSort"
                    style="overflow-x: auto;">
            <template >
                    <b-table-column  v-slot="props" :field="$t('CHECKPOINT.CONTADOR')" label="">
                        <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="amostraCodigoDeBarras" :label="$t('CHECKPOINT.AMOSTRA')" sortable>                        
                        <span class="tag" :style="props.row.statusHtmlStyle" :title="props.row.statusTitle"> {{ props.row.statusSigla }} </span>
                        {{ props.row.amostraCodigoDeBarras }}    
                        <div class="is-size-7 has-text-weight-bold">
                            <span v-if="props.row.amostraRefMae && props.row.amostraTipo == 2">AL: </span>
                            <span v-else-if="props.row.amostraRefMae && props.row.amostraTipo == 3">LA: </span>
                            <span v-else-if="props.row.amostraRefMae && props.row.amostraTipo == 4">EX: </span>
                            {{ props.row.amostraRefMaeCodBarras }}
                        </div>                      
                    </b-table-column>

                    <b-table-column  v-slot="props" field="guia" :label="$t('CHECKPOINT.GUIA')" sortable>
                        {{ props.row.guia }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="pacienteNome" :label="$t('SISTEMA.PACIENTE')" sortable>
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="materialNome" :label="$t('CHECKPOINT.MATERIAL')" sortable>
                        {{ props.row.materialNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="conservanteNome" :label="$t('CHECKPOINT.CONSERVANTE')" sortable>
                        {{ props.row.conservanteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="setorNome" :label="$t('CHECKPOINT.SETOR')" sortable>
                        {{ props.row.setorNome }}
                            <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Exames" :label="$t('CHECKPOINT.EXAMES')">
                        <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                            {{item}}<span> </span>
                        </span>
                    </b-table-column>

                    <b-table-column v-slot="props" field="Acondicionamento" label="">
                        <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" title="Acondicionamento: Congelada">C</span>
                        <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" title="Acondicionamento: Refrigerada">R</span>
                        <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" title="Acondicionamento: Ambiente">A</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Incidencia" :label="$t('CHECKPOINT.INCIDENCIA')">
                        <span class="tag pull-right is-success is-light button"  @click="incidenciaManual(props.row);" v-if="props.row.incidencia == 'A'" :title="$t('CHECKPOINT.ACEITARAMOSTRA')">A</span>
                        <span class="tag pull-right is-warning is-light " v-if="props.row.incidencia == 'R'" :title="$t('CHECKPOINT.ACEITARRESTRICAO')">R</span>
                        <span class="tag pull-right is-danger is-light " v-if="props.row.incidencia == 'N'" :title="$t('CHECKPOINT.NAOACEITARAMOSTRA')">N</span>
                    </b-table-column>

                    <b-table-column v-slot="props">
                        <span v-if="props.row.amostraCheckpoint" class="tag pull-right is-warning" :title="$t('CHECKPOINT.EFETUADOCHECKPOINT')">
                            <b-icon icon="exclamation"></b-icon>
                        </span>
                        <span v-if="(props.row.exameAliquota)" >
                                <b-button native-class="panel-block" size="is-small" icon-left="flask-outline" type="is-primary" @click="abrirSolicitarNovaAmostra(props.row.amostra)">
                                    {{$t('CHECKPOINT.ALIQUOTAAMOSTRA')}}
                                </b-button>
                        </span>                    
                    </b-table-column>
                    <b-table-column v-slot="props" field="Status" :label="$t('CHECKPOINT.AMOSTRASTATUS')">
                            <b-select size="is-small" v-model="props.row.amostraStatusId" @input="alteracaoStatus(props.row)">
                                <option value=""></option>
                                <option v-for="option in amostraStatus"
                                        :value="option.id"
                                        :key="option.id">
                                    {{ option.nome }}
                                </option>
                            </b-select>
                    </b-table-column>
            </template>
        </b-table>
        <br>
        <div class="box" style="overflow-x: auto;">

            <div class="columns">
                <div class="column">
                    <div class="is-light">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <label><strong>{{$t('CHECKPOINT.CODIGOBARRAS')}}</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <b-input v-model="amostraCodigoDeBarras" ref="amostraCodigoDeBarras" id="amostraCodigoDeBarras"></b-input>
                                </div>
                                <div class="control">
                                    <b-button native-type="submit">
                                        {{$t('CHECKPOINT.INCLUIR')}}
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </div>                    
                </div>
                <div class="column">
                    <div class="is-light">
                        <label><strong>{{$t('CHECKPOINT.CODIGOGUIA')}}</strong></label>
                        <div class="field has-addons">
                            <div class="control">                                        
                                <b-input type="text"  ref="guiaId" v-model="guiaId" @keyup.native.enter="incluirGuia(resultEdit)"></b-input>
                            </div>
                            <div class="control">
                                <a class="button" @click="incluirGuia();">
                                {{$t('CHECKPOINT.INCLUIR')}}
                                </a>
                            </div>
                            <div class="control">
                                <a class="button is-info" @click="incluirPendencias();">
                                {{$t('CHECKPOINT.PENDENCIA')}}
                                </a>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="column">                    
                        <div class="is-light">
                            <label><strong>{{$t('CHECKPOINT.LOTEAMOSTRAS')}}</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <input type="text" v-model="amostraLoteId" class="input" ref="amostraLoteId" id="amostraLoteId" />
                                </div>
                                <div class="control">
                                    <a class="button" @click="incluirLote();">
                                    {{$t('CHECKPOINT.INCLUIR')}}
                                    </a>
                                </div>
                            </div>
                        </div>                                            
                </div>
                <div class="column">
                    <div class="is-light">                            
                            <label><strong>{{$t('CHECKPOINT.AMOSTRASTATUS')}}</strong></label>
                            <b-select v-model="statusId"  @input="definirStatus(statusId)">
                                <option></option>
                                <option v-for="(status,index) in amostraStatus" :key="index"
                                        :value="status.id">
                                    {{ status.nome }}
                                </option>
                            </b-select>                        
                    </div>
                </div>  
            </div>    

            <div class="columns">
                <div class="column is-6">
                    <div class="is-light">
                        <label><strong>{{$t('CHECKPOINT.DESCRICAO')}}</strong></label>
                        <input type="text" v-model="descricao" class="input" maxlength="100"/>
                    </div>
                </div>
                <div class="column">
                    <div class="is-light">                            
                            <label><strong>{{$t('CHECKPOINT.BANDEJA')}}</strong></label>
                            <b-select v-model="containerId">
                                <option></option>
                                <option v-for="(container,index) in containers" :key="index"
                                        :value="container.id">
                                    {{ container.nome }}
                                </option>
                            </b-select>                        
                    </div>
                </div>                
            </div>                                                               
        </div>
        <div class='box' style="overflow-x: auto;">
            <div class="columns">
                <div class="column is-one-third">
                    <div class="columns">
                        <div class="column is-6">
                            {{$t('CHECKPOINT.FUNCIONARIORESP')}}
                            <b-select v-model="funcionarioId">
                                <template v-for="(item, index) in this.funcionariosAtivos">
                                    <option v-if="funcionarioId == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3" v-if="this.funcionariosAtivos.length > 0">
                            <br>
                            <div class="control">
                                <input type="text" :placeholder="$t('CHECKPOINT.IDENTIFICADOR')" v-model="funcionarioIdentificador" class="input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <label><strong>{{$t('CHECKPOINT.TEMPERATURA')}}</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column">
                    <label><strong>{{$t('CHECKPOINT.UMIDADE')}}</strong></label>
                    <input type="number" v-model="umidade" class="input" />
                </div>
                <div class="column">
                    <label><strong>{{$t('CHECKPOINT.LATITUDE')}}</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column">
                    <label><strong>{{$t('CHECKPOINT.LONGITUDE')}}</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
                <div class="column">
                    <br>
                    <b-switch v-model="bancada">
                        {{$t('CHECKPOINT.EMITEBANCADA')}}
                    </b-switch>
                </div>
                <div class="column">
                    <label class="label">{{$t('CHECKPOINT.ENVIAINTEGRADOR')}}</label>
                    <b-select :placeholder="$t('CHECKPOINT.INTEGRADOR')" v-model="integradorId">
                        <option value=""></option>
                        <option v-for="option in integradores"
                                :value="option.id"
                                :key="option.id">
                            {{ option.nome }}
                        </option>
                    </b-select>
                </div>
            </div>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-primary" @click="imprimir();">
                        {{$t('CHECKPOINT.IMPRIMIR')}}
                    </b-button>
                    <b-button type="is-success" :loading="loading" icon-left="check-circle" @click="enviarCheckpoint();">
                        {{$t('SISTEMA.ENVIAR')}}
                    </b-button>
                    <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                        {{$t('CHECKPOINT.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapGetters, mapState } from 'vuex'
    import modalRecebimentoIncidencia from '@/components/atendimento/modalRecebimentoIncidencia.vue'
    import modalRecebimentoIncidenciaManual from '@/components/atendimento/modalRecebimentoIncidenciaManual.vue'
    import modalSolicitarNovaAmostraCheckPoint from '@/components/atendimento/modalSolicitarNovaAmostraCheckPoint.vue'
    import modalAlteracaoStatusAmostraCheckPoint from '@/components/atendimento/modalAlteracaoStatusAmostraCheckPoint.vue'

    export default {
        data() {
            return {
                funcionarioId: null,
                funcionarioIdentificador: "",
                funcionariosAtivos: [],
                containers: [],
                status: [],
                amostraStatus: [],
                setoresEscolhidos: [],
                setores: [],
                conveniosEscolhidos: [],
                convenios: [],
                amostras: [],
                amostraCodigoDeBarras: "",
                amostraLoteId: "",
                guiaId: "",
                descricao: null,
                containerId: "",
                statusId: "",
                temperatura: "",
                umidade: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                bancada: localStorage.emitirBancadaCheckpoint ?? false,
                equipmentId: this.$route.params?.equipmentid ?? null,
                integradorId: null,
                ordenarPor: null,
                ordenar: 'asc',
                opcoesBancada: null,
            }
        },
        watch: {
            funcionarioIdentificador: function(val){
                if(this.funcionariosAtivos.filter(func => func.identificador == val).length > 0){
                    this.funcionarioId = this.funcionariosAtivos.filter(func => func.identificador == val)[0].id
                }
            },
            amostraCodigoDeBarras: function (val) {
                //verifica se existe na lista
                if(this.amostras.filter(am => am.amostraCodigoDeBarras == val).length > 0){ 
                    if(this.amostras.filter(am => am.amostraCodigoDeBarras == val).length == 0) {
                        this.amostras.filter(am => am.amostraCodigoDeBarras == val).forEach(item => {
                                item.amostraBipada = true;
                                this.checkedRows.push(item);
                            });
                    }                   

                    if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                        this.$buefy.dialog.alert({
                            title: this.$t('CHECKPOINT.AMOSTRASELECIONADA'),
                            message: this.$t('CHECKPOINT.AMOSTRAJAINCLUIDA', { msg:  this.amostraCodigoDeBarras }),
                            type: 'is-warning',
                            hasIcon: true,
                            onConfirm: () => this.$refs.amostraCodigoDeBarras.focus()
                        });
                    }

                    this.amostraCodigoDeBarras = null;

                }
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CHECKPOINT.ATENDIMENTO'),
                    this.$t('CHECKPOINT.LOTEAMOSTRAS'),
                    this.$t('CHECKPOINT.CHECKPOINT'),
                ]
            },
            ...mapState([
                'integradores',
                'config'
            ])
        },
        mounted(){
            this.$http.get('/api/search/FuncionariosAtivos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.funcionariosAtivos = data;
                    }
                });

                const params = [                                                          
                    `perPage=200`
                ].join('&')

                this.$http.get(`/api/manutencao/sorotecascontainers?${params}`)
                    .then(({ data }) => {
                        this.containers = data.lista;
                    });
                    

            this.$http.get('/api/search/AmostrasStatus')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.amostraStatus = data;
                    }
                });
                
            this.$refs.amostraCodigoDeBarras.focus();
        },
        created() {
            this.$getLocation({})
                .then(coordinates => {
                    this.latitude = coordinates.lat
                    this.longitude = coordinates.lng
                });

            this.$http.get('/api/manutencao/sorotecacontainer?id=' + this.$route.params.id)
                .then(res => {
                    this.containers = res.data;
                    
                });                
        },
        methods:{

            cancelarAlteracaoStatus(amostra,statusLote){
                if(statusLote){
                    setTimeout(() => {
                        this.checkedRows.forEach(amostra => {
                            amostra.amostraStatusId = amostra.amostraStatusIdOriginal;
                        });                    
                     },500);                                        

                }else{
                    setTimeout(() => { amostra.amostraStatusId = amostra.amostraStatusIdOriginal; },500);                    
                }

                this.$forceUpdate();                     

            },
            definirStatus(status){
                if(this.checkedRows.length > 0){
                   const amostraStatusEqual = this.checkedRows.every(amostra => amostra.amostraStatusId == "" || amostra.amostraStatusId == 0 || amostra.amostraStatusId == this.checkedRows.filter(amostra => amostra.amostraStatusId != "" && amostra.amostraStatusId > 0)[0].amostraStatusId)

                   if(!amostraStatusEqual){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CHECKPOINT.ALTERARSTATUS'),
                            type: 'is-warning',
                            queue: false
                        });

                        return;
                   }

                   if(this.checkedRows.filter(x => x.amostraStatusId > 0 && x.amostraStatusId != "").length > 0) {
                        this.alteracaoStatus(this.checkedRows[0], true);
                   }

                    this.checkedRows.forEach(x => {
                        x.amostraStatusId = status
                    });
                }
            },
            alteracaoStatus(amostra, statusLote){                

                if(amostra.amostraStatusId && amostra.amostraStatusId >0 ){

                    const amostraStatusAlterado = this.amostraStatus.filter(x=>x.id == amostra.amostraStatusId)[0];                     

                    //Verificar se Ordem do status alterado é menor que status atual
                    // Caso sim abrir tela de motivos
                    if(amostraStatusAlterado.ordem < amostra.amostraStatusOrdem || statusLote){  
                        this.$buefy.modal.open({
                            parent: this,
                            component: modalAlteracaoStatusAmostraCheckPoint,
                            props: {
                                amostra: amostra,
                                amostras: this.checkedRows,
                                statusLote: statusLote     
                            },
                            events: {
                                cancelarAlteracaoStatus: this.cancelarAlteracaoStatus
                            },                            
                            hasModalCard: true,
                            trapFocus: true,
                            fullScreen: true
                        })                        

                    }

                }

            },
            abrirSolicitarNovaAmostra(amostra) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalSolicitarNovaAmostraCheckPoint,
                    props: {
                        amostra: amostra                        
                    },
                    events: {
                        loadAmostras: this.loadAmostras
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },  
            loadAmostras(codigoBarra){

                this.amostraCodigoDeBarras = codigoBarra;
                this.incluirAmostra();
                return true;
            },    
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            incluirAmostra(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluir?codigoDeBarras=' + this.amostraCodigoDeBarras)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            data[i].amostraStatusIdOriginal = data[i].amostraStatusId;
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('CHECKPOINT.MENSAGEMFILTROSETOR'),
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('CHECKPOINT.MENSAGEMFILTROCONVENIO'),
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }
                            
                            if(data[i].temIncidencia){
                                this.incidencia(data[i]);
                            }
                            else {
                                this.amostras.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }
                        }
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CHECKPOINT.AMOSTRANAOENCONTRADA'),
                            type: 'is-warning',
                            queue: false
                        });
                    }

                    this.$refs.amostraCodigoDeBarras.focus();
                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

                this.amostraCodigoDeBarras = "";
            },
            incluirLote(){
                this.erros = [];
                this.loading = true;

                if(this.amostraLoteId){
                    this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirLote?amostraLoteId=' + this.amostraLoteId)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {
                                if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                    if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                        this.$buefy.toast.open({
                                            duration: 5000,
                                            message: this.$t('CHECKPOINT.MENSAGEMFILTROSETOR'),
                                            type: 'is-warning',
                                            queue: false
                                        });
                                        continue;
                                    }
                                }
    
                                if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                    if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                        this.$buefy.toast.open({
                                            duration: 5000,
                                            message: this.$t('CHECKPOINT.MENSAGEMFILTROCONVENIO'),
                                            type: 'is-warning',
                                            queue: false
                                        });
                                        continue;
                                    }
                                }

        
                                if(data[i].temIncidencia){
                                    this.incidencia(data[i]);
                                }
                                else {
                                    this.amostras.push(data[i]);
                                    this.checkedRows.push(data[i]);
                                }                            
                            }
                        }
    
                        this.$refs.amostraLoteId.focus();
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error)
                        this.erros.push(error);
                        this.loading = false;
                    }); 
    
                    this.amostraLoteId = "";
                }
                else{
                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CHECKPOINT.LOTENAOENCONTRADO'),
                            type: 'is-warning',
                            queue: false
                        });
                }
            },
            incluirGuia(){
                this.erros = [];
                this.loading = true;
                
                if(this.guiaId){
                    this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirGuia?guiaId=' + this.guiaId)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {
                                if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                    if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                        this.$buefy.toast.open({
                                            duration: 5000,
                                            message: this.$t('CHECKPOINT.MENSAGEMFILTROSETOR'),
                                            type: 'is-warning',
                                            queue: false
                                        });
                                        continue;
                                    }
                                }
    
                                if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                    if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                        this.$buefy.toast.open({
                                            duration: 5000,
                                            message: this.$t('CHECKPOINT.MENSAGEMFILTROCONVENIO'),
                                            type: 'is-warning',
                                            queue: false
                                        });
                                        continue;
                                    }
                                }

                                if(data[i].temIncidencia){
                                    this.incidencia(data[i]);
                                }
                                else {
                                    this.amostras.push(data[i]);
                                    this.checkedRows.push(data[i]);
                                }                            
    
                            }
                        }
    
                        this.$refs.guiaId.focus();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.erros.push(error);
                        this.loading = false;
                    }); 
    
                    this.guiaId = "";
                }
                else{
                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CHECKPOINT.CODIGOGUIANAOENCONTRADO'),
                            type: 'is-warning',
                            queue: false
                        });
                }
            },
            incluirPendencias(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirPendenciasDeBancada')
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('CHECKPOINT.MENSAGEMFILTROSETOR'),
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('CHECKPOINT.MENSAGEMFILTROCONVENIO'),
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            this.amostras.push(data[i]);
                            this.checkedRows.push(data[i]);
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

            },
            enviarCheckpoint(){
                if(this.checkedRows.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('CHECKPOINT.AMOSTRANAOSELECIONADA'),
                        type: 'is-info',
                        queue: false
                    })

                    return;
                }

                this.erros = [];
                this.loading = true;

                if(this.bancada){
                    localStorage.emitirBancadaCheckpoint = true

                    this.carregarOpcoesBancada();

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('CHECKPOINT.AMOSTRASENVIADAS'),
                        type: 'is-info',
                        queue: false
                    })
                }
                else {
                    localStorage.emitirBancadaCheckpoint = false
                }

                this.checkedRows.filter(am => am.amostraStatusId == null ||  am.amostraStatusId == "").forEach(item => {
                    item.amostraStatusId = 0;
                });


                const amostraLoteCheckpoint = {
                    amostras: this.checkedRows, 
                    funcionarioId: this.funcionarioId,
                    EnviaParaIntegradorId: this.integradorId, 
                    temperatura: this.temperatura, 
                    umidade: this.umidade, 
                    latitude: this.latitude, 
                    longitude: this.longitude,
                    emitirBancada: this.bancada,
                    opcoesBancada: this.opcoesBancada,
                    equipmentId: this.equipmentId,
                    descricao: this.descricao,
                    containerId: (this.containerId && this.containerId!="" && this.containerId>0) ? this.containerId : null
                };

                if(amostraLoteCheckpoint.containerId >0){                    
                    const containerSelecionado = this.containers.filter(x=>x.id == amostraLoteCheckpoint.containerId )[0];

                    if(containerSelecionado.posicoes.length < this.amostras.length){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('CHECKPOINT.AVISOBANDEJA'),
                            type: 'is-warning',
                            hasIcon: true
                        });      
                        this.loading = false;                  
                        return;
                    }                                        
                }
                
                this.$http.post('/api/atendimento/AmostraLoteCheckpoint', amostraLoteCheckpoint, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        if(this.bancada && this.equipmentId==null){
                            const file = new Blob([data], { type: 'application/pdf' });
                            const fileURL = window.URL.createObjectURL(file);
                            window.open(fileURL);
                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.erros.push(error);
                        this.loading = false;
                    }); 



                this.amostras = [];
                this.checkedRows = [];
            },
            carregarOpcoesBancada() {
                this.opcoesBancada = '';

                this.carregarOpcao('SeparaSetoresPorPagina');
                this.carregarOpcao('SeparaLocaisPorPagina');
                this.carregarOpcao('SeparaExamesPorPagina');
                this.carregarOpcao('SeparaPacientesPorPagina');
                this.carregarOpcao('SomenteExamesUrgentes');
                this.carregarOpcao('SomenteExamesSemResultado');
                this.carregarOpcao('ExibeConvenio');
                this.carregarOpcao('ExibeNomeDoMedico');
                this.carregarOpcao('ExibeMaterial');
                this.carregarOpcao('ExibeConservante');
                this.carregarOpcao('ExibeLocal');
                this.carregarOpcao('ExibeDataDeRetorno');
                this.carregarOpcao('ExibeLeito');
                this.carregarOpcao('ExibeRascunho');
                this.carregarOpcao('ExibeUltimosResultados');
                this.carregarOpcao('ExibeNascimento');
                this.carregarOpcao('OrdenaPeloCodigoDaGuia');
                this.carregarOpcao('OrdenaPeloNomeDoPaciente');
                this.carregarOpcao('OrdenaPelaDataDeSolicitacao');
                this.carregarOpcao('EmiteEmPapelRazao');
                this.carregarOpcao('IncluiEspacoParaAssinaturas');
                this.carregarOpcao('ExibeCodigoDeBarrasDaAmostra');
                this.carregarOpcao('ExibeObservacaoPaciente');
            },
            carregarOpcao(nome) {
                const pre = "bancada";

                if (localStorage.getItem(pre + nome) && localStorage.getItem(pre + nome) == 'true') {
                    this.opcoesBancada += this.opcoesBancada + nome + ",";
                }
            },
            imprimir(){
                const modelo = window.localStorage.getItem("etiquetaAtual");

                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.checkedRows?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }
                else {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.amostras?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }
            },            
            getSetores(text) {
                if(text){
                    this.$http.get('/search/Setor?nome=' + text)
                        .then(res => {
                            this.setores = res.data.map(x => x.nome);
                        });
                }
            },
            getConvenios(text){
                if(text){
                    this.$http.get('/search/Convenio?nome=' + text)
                        .then(res => {
                            this.convenios = res.data.map(x => x.nome);
                        });
                }
            },
            confirmarIncidencia(amostra){
                this.amostras.push(amostra);
                this.checkedRows.push(amostra);
            },
            atualizarIncidencia(amostraIndicendia){
                this.amostras.filter(amostra => amostra.amostraCodigoDeBarras == amostraIndicendia.amostraCodigoDeBarras).forEach(item => {               
                    
                    item.motivoIncidencia = amostraIndicendia.motivoIncidencia;
                    item.incidencia = amostraIndicendia.incidencia;
                });
            },    
            incidencia(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidencia,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        confirmarIncidencia: this.confirmarIncidencia
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            incidenciaManual(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidenciaManual,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        atualizarIncidencia: this.atualizarIncidencia
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            }   
        }
    }
</script>