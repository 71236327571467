<template>
  <section>
    <b-loading :active.sync="loading"></b-loading>
    <div class="box  is-hero-bar is-main-hero">
      <b-table :data="lista" 
        striped 
        hoverable
        :checked-rows.sync="checkedRows"
        checkable
      >
        <template >
          <b-table-column  v-slot="props">
            <div class="columns">
              <div class="column is-three-quarters-desktop is-full-mobile">
                <router-link target='_blank' :to="{ name: 'guia', params: { id: props.row.guiaId } }">
                    <b> {{props.row.guiaId}} - {{props.row.pacienteNome}}</b>
                </router-link>
              </div>
              <div class="column is-full-mobile">
                <b-switch  v-model="props.row.guiaConferida" @change.native="salvar({guiaConferida: props.row.guiaConferida, guiaId: props.row.guiaId})">
                        {{$t('AUTORIZACAOTISS.GUIACONFERIDA')}}
                </b-switch>
              </div>
              <div class="column is-full-mobile">
                <b>{{$t('AUTORIZACAOTISS.VALOR')}}: {{props.row.valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</b>
              </div>
            </div>
            <div class="columns  is-multiline is-mobile">
              <div class="column is-3-desktop is-full-mobile">
                <small><b>{{$t('AUTORIZACAOTISS.PACIENTE')}}:</b> </small>
                <br/>
                {{ props.row.pacienteNome }}
              </div>
              <div class="column is-3-desktop is-full-mobile">
                <small>{{$t('AUTORIZACAOTISS.CARTEIRA')}}:</small>
                <br/>
                <div class="column is-4">
                  <b-input size="is-small" type="text" @keyup.enter.native="salvar({pacienteInscricao: props.row.pacienteInscricao, guiaId: props.row.guiaId}, $event)" v-model="props.row.pacienteInscricao"></b-input>
                </div>
              </div>
              <div class="column is-3-desktop is-full-mobile">
                <small>{{$t('AUTORIZACAOTISS.SOLICITANTE')}}:</small>
                <br/>
                <b>{{ props.row.solicitanteNome }}</b>
              </div>
              <div class="column is-3-desktop is-full-mobile">
                <small>{{$t('AUTORIZACAOTISS.ORIGEM')}}:</small>
                <br/>
                <b>{{ props.row.localOrigemNome }}</b>
              </div>
            </div>
            <div class="columns  is-multiline is-mobile">
                <div class="column is-3-desktop is-full-mobile">
                  <small>{{$t('AUTORIZACAOTISS.SADTNUMERO')}}:</small>
                  <br/>
                  <div v-if="!props.row.controleDeGuiasSADT" class="column is-5">
                    <b-input size="is-small" type="text" @keyup.enter.native="salvar({autorizacaoGuia: props.row.autorizacaoGuia, guiaId: props.row.guiaId}, $event)" v-model="props.row.autorizacaoGuia"></b-input>
                  </div>
                  <div v-else class="column is-5">
                    <b-input size="is-small" type="text" @keyup.enter.native="salvar({sadtNumero: props.row.sadtNumero, sadtId: props.row.sadtId, guiaId: props.row.guiaId}, $event)" v-model="props.row.sadtNumero"></b-input>
                  </div>
                </div>
                <div  class="column is-3-desktop is-full-mobile">
                  <small>{{$t('AUTORIZACAOTISS.SENHA')}}:</small>
                  <br/>
                  <div v-if="!props.row.controleDeGuiasSADT" class="column is-5">
                    <b-input size="is-small" type="text" @keyup.enter.native="salvar({autorizacaoSenha: props.row.autorizacaoSenha, guiaId: props.row.guiaId}, $event)" v-model="props.row.autorizacaoSenha"></b-input>
                  </div>
                  <div v-else class="column is-5">
                    <b-input size="is-small" type="text" @keyup.enter.native="salvar({sadtAutorizacaoSenha: props.row.sadtAutorizacaoSenha, sadtId: props.row.sadtId, guiaId: props.row.guiaId}, $event)" v-model="props.row.sadtAutorizacaoSenha"></b-input>
                  </div>
                </div>
                <div class="column is-3-desktop is-full-mobile">
                  <small>{{$t('AUTORIZACAOTISS.DATAAUTORIZACAO')}}:</small>
                  <br/>
                  <div v-if="!props.row.controleDeGuiasSADT" class="column is-5">
                    <b-datepicker size="is-small" icon="calendar-today" editable  @blur="salvar({autorizacao: props.row.autorizacao, guiaId: props.row.guiaId}, $event)" v-model="props.row.autorizacao" trap-focus></b-datepicker>
                  </div>
                  <div v-else class="column is-5">
                    <b-datepicker size="is-small" icon="calendar-today" editable  @blur="salvar({sadtAutorizacao: props.row.sadtAutorizacao, sadtId: props.row.sadtId, guiaId: props.row.guiaId}, $event)" v-model="props.row.sadtAutorizacao" trap-focus></b-datepicker>
                  </div>
                </div>
                <div  class="column is-3-desktop is-full-mobile">
                  <small>{{$t('AUTORIZACAOTISS.DATAVALIDADE')}}:</small>
                  <br/>
                  <div v-if="!props.row.controleDeGuiasSADT" class="column is-5">
                    <b-datepicker size="is-small" icon="calendar-today" editable @blur="salvar({autorizacaoSenhaValidade: props.row.autorizacaoSenhaValidade, guiaId: props.row.guiaId}, $event)" v-model="props.row.autorizacaoSenhaValidade" trap-focus></b-datepicker>
                  </div>
                  <div v-else class="column is-5">
                      <b-datepicker size="is-small" icon="calendar-today" editable @blur="salvar({sadtAutorizacaoSenhaValidade: props.row.sadtAutorizacaoSenhaValidade, sadtId: props.row.sadtId, guiaId: props.row.guiaId}, $event)" v-model="props.row.sadtAutorizacaoSenhaValidade" trap-focus></b-datepicker>
                    </div>
                </div>
                
            </div>
            <b-table :data="props.row.procedimentos.filter(x => !x.exameOutrasDespesas)" 
              striped 
              hoverable
              style="border: 1px solid rgba(211, 211, 211, 0.6)"
            >
              <template >
                <div class="columns is-mobile">
                  <div class="column is-4-desktop is-full-mobile">
                    <b>{{$t('AUTORIZACAOTISS.QTDE')}}: {{props.row.qtde}}</b>
                  </div>
                </div>
                <b-table-column v-slot="props" field="procedimentos" :label="$t('AUTORIZACAOTISS.PROCEDIMENTOS')">
                      {{ props.row.exameApelido }}
                </b-table-column>
              
                <b-table-column v-slot="props" field="valor" :label="$t('AUTORIZACAOTISS.VALOR')">
                      {{ props.row.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                </b-table-column>
                <b-table-column v-slot="props" field="dataAgenda" :label="$t('AUTORIZACAOTISS.DATAAGENDA')">                   
                      {{ props.row.agendaDataHora | moment('DD/MM/YYYY HH:mm') }}
                </b-table-column>
                <b-table-column v-slot="props" field="assinado" :label="$t('AUTORIZACAOTISS.LAUDADO')">
                      <a @click="visualizarPdf(props.row.guiaId, props.row.laudoRestrito)" type="button" v-if="props.row.assinado">Visualizar</a>
                </b-table-column>
              </template>
                
            </b-table>
          </b-table-column>
        </template>
      </b-table>
    </div>
    <nav class="level">
          <div class="level-item">
              <p class="buttons">
                  <b-button type="is-success"
                            native-type="button"
                            :loading="loading"
                            @click="enviar()">
                      {{$t('FATURAR.CRIARDOCUMENTO')}}
                  </b-button>
              </p>
          </div>
    </nav>
  </section>
</template>

<script>
  import { mapState } from 'vuex'   

export default {
    data(){
      return {
        lista: null,
        checkedRows: [],
        salvandoDados: false,
        loading: false,
        parametros: null,
      }
    },
    computed: {
      ...mapState([
          'config'
      ]),
    },
    created(){
      this.lista = this.$route.params.lista??JSON.parse(localStorage.getItem("lista"));
      this.parametros = this.$route.params.parametros;

      this.lista.forEach(guia => {
        guia.autorizacao = guia.autorizacao  != null ? this.$moment(guia.autorizacao).toDate() : null
        guia.autorizacaoSenhaValidade =  guia.autorizacaoSenhaValidade != null ? this.$moment(guia.autorizacaoSenhaValidade).toDate() : null
        guia.sadtAutorizacao = guia.sadtAutorizacao != null ? this.$moment(guia.sadtAutorizacao).toDate() : null
        guia.sadtAutorizacaoSenhaValidade =  guia.sadtAutorizacaoSenhaValidade !=  null ? this.$moment(guia.sadtAutorizacaoSenhaValidade).toDate() : null
          
      });

      localStorage.lista = JSON.stringify(this.lista)
    },
    methods: {
      salvar(item, event){
        this.loading = true;
        localStorage.lista = JSON.stringify(this.lista)  

        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('AUTORIZACAOTISS.SALVANDODADOS'),
          type: 'is-info',
          queue: false
        });

        this.$http.post('/api/financeiro/registrarAlteracoesRelatorioValidarTiss', item)
        .then(() => {
          event.target.blur(); 
          this.loading = false;
        })
        .catch((error) => {
            this.loading = false;
        }); 

      },
      visualizarPdf(id, laudoRestrito){   
        window.open('/Guia/Pdf?id=' + id + '&laudoRestrito=' + laudoRestrito);
      },
      enviar(){
        this.loading = true;

        if (this.checkedRows != null && this.checkedRows.length > 0){
            this.parametros.guiaId = this.checkedRows.map(item => item.guiaId)

          const faturar = this.parametros;
          
          this.$http.post('/api/financeiro/ReceberFaturar', faturar)
          .then(res => res.data)
          .then(data => {
              this.$router.push({ name: 'lista-contas-receber' });
              
              this.loading = false;
          })
          .catch((error) => {
              this.loading = false;
          });
        }
        else {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('AUTORIZACAOTISS.SELECIONARGUIA'),
            type: 'is-danger',
            queue: false
          });
          this.loading = false;
        }
      },
    }
}
</script>
