<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('CONDICOESCADASTRO.SALVANDOCADASTRO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="descrição" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONDICOESCADASTRO.DESC')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.descricao"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider name="model.especieDePagamento" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONDICOESCADASTRO.ESPECIEPAG')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-select expanded :placeholder="$t('CONDICOESCADASTRO.SELESPECIEPAG')" v-model="model.especieDePagamento">
                                                <option value=""></option>
                                                <option v-for="option in model.especies"
                                                        :value="option.id"
                                                        :key="option.id">
                                                    {{ option.nome }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required" name="Qtde. Parcelas" v-slot="{ errors, valid }">
                                        <b-field expanded :label="$t('CONDICOESCADASTRO.QTDEPARCEL')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number" max="300"  min="0" v-model="model.qtdeParcelas"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                                                        
                                <div class="column">
                                    <ValidationProvider name="model.bandeira" v-slot="{ errors, valid }">
                                        <b-field  :label="$t('CONDICOESCADASTRO.BANDEIRA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-select expanded :placeholder="$t('CONDICOESCADASTRO.SELBANDEIRA')" v-model="model.bandeira.id">
                                                <option v-for="bandeira in model.bandeiras"
                                                        :key="bandeira.id"
                                                        :value="bandeira.id">
                                                    {{ bandeira.nome }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider name="model.prazoVencimento" v-slot="{ errors, valid }">
                                        <b-field expanded :label="$t('CONDICOESCADASTRO.VENCIMENTO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number" max="500" min="0" v-model="model.prazoVencimento"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">                                                                     
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.prazoVencimentoPrimeiraParcela" v-slot="{ errors, valid }">
                                        <b-field expanded :label="$t('CONDICOESCADASTRO.VENCIMENTOPRIMEIRAPARCELA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number" max="500" min="0" v-model="model.prazoVencimentoPrimeiraParcela"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                            
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.emiteNotaFiscal">
                                            {{$t('CONDICOESCADASTRO.EMITENOTAFISCAL')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.gerarBoleto">
                                            {{$t('CONDICOESCADASTRO.GERARBOLETO')}}
                                        </b-switch>
                                    </div>
                                </div>   
                            </article>
                            <article class="tile is-child">
                                <historico-alteracoes :tabela="'CondicaoPagamento'" :chave="model.id"></historico-alteracoes>
                            </article>
                        </article>
                    </div>                 
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('condicaopagamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('CONDICOESCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CONDICOESCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        components: {
            titlebar,
            historicoAlteracoes
        },
        data() {
            return {
                model: {
                    id: null,
                    descricao: null,
                    bandeiras: [],
                    especies: [],
                    bandeira: null,
                    especieDePagamento: null,
                    qtdeParcelas: null,
                    prazoVencimento: null,
                    prazoVencimentoPrimeiraParcela: null
                },
                salvandoDados: false
            }
        },
        created() {
            let url = '/api/manutencao/condicaopagamento';
            if (this.$route.params.id > 0){
                url = '/api/manutencao/condicaopagamento?id=' + this.$route.params.id;
            }
            this.$http.get(url)
                .then(res => {
                    this.model = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONDICOESCADASTRO.MANUTENCAO'),
                    this.$t('CONDICOESCADASTRO.CONDPAG'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                if(this.model.qtdeParcelas == null || this.model.qtdeParcelas == 0){
                    this.model.qtdeParcelas = 1;
                }
                
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/condicaopagamento`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            }
        }
    }
</script>